export default {
    title: 'Sales and Quantity',
    location: '/business/total-sales/sales-and-quantity-per-country',
    tabs: [
        {
            name: 'By Country',
            link: `/business/total-sales/sales-and-quantity-per-country`
        },
        {
            name: 'Trend',
            link: `/business/total-sales/sales-and-quantity-per-country/:market`
        },
        {
            name: 'By HFB',
            link: '/business/total-sales/sales-and-quantity-per-country/by-hfb'
        },
        {
            name: 'By PMA',
            link: '/business/total-sales/sales-and-quantity-per-country/by-pma'
        }
    ]
}